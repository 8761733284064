import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { API_URL, API_URL_FRONTEND } from "./config";

export const PrivacyDispatcher = (props) => {
  const [page, setPage] = useState({});
  const [appData, setAppData] = useState({});
  const params = new URLSearchParams(props.location.search);
  const isInstitutional = params.get("institutional") === "true";
  console.log("isInstitutional", isInstitutional);

  const loadPrivacyPolicy = async () => {
    try {
      const res = await axios({
        url: `${API_URL_FRONTEND}/pages/guida-eliminazione-account-app${
          !!isInstitutional ? "?institutional=true" : ""
        }`,
        method: "GET",
        headers: {
          storeid: props.match.params.storeId,
        },
      });
      return res.data;
    } catch (e) {
      console.log(e);
      props.history.push("/errore");
    }
  };
  const laodData = async () => {
    try {
      const res = await axios({
        url: `${API_URL_FRONTEND}/store${
          !!isInstitutional ? "/institutional" : ""
        }`,
        method: "GET",
        headers: {
          storeid: props.match.params.storeId,
        },
      });
      return res.data;
    } catch (e) {
      console.log(e);
      props.history.push("/errore");
    }
  };

  useEffect(async () => {
    const page = await loadPrivacyPolicy();
    const data = await laodData();
    setPage(page);
    setAppData(data);
  }, []);

  return (
    <div style={{ margin: 24 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 16,
        }}
      >
        {appData?.loghiTemplate?.logoImage ? (
          <img
            src={appData?.loghiTemplate?.logoImage}
            height={51 * 2}
            width={173 * 2}
            alt="logo"
          />
        ) : (
          <h1>{appData?.datiFarmacia?.siteName?.split(".it")[0]}</h1>
        )}
      </div>
      <h2>{page?.title}</h2>
      <div>
        <div
          // contentEditable="true"
          dangerouslySetInnerHTML={{ __html: page?.content }}
        ></div>
      </div>
    </div>
  );
};
